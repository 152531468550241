@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Gugi&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Solution Caisse */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Garage Ferrand + Poppins */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@layer base {
    * {
        
        @apply font-[Poppins];   
    }

    li {
        @apply mx-4;
        @apply cursor-pointer;
    }
}

html {
    scroll-behavior: smooth;
}

body {
    color: #333333;
    margin: 0;
    padding: 0;
}

.menu {
    position: relative;
}

.menu::after {
    content: '';
    width: 0;
    height: 2px;
    background-color: #B8DFE6;
    position: absolute;
    left: 0;
    bottom: -.5rem;
    transition: width .3s;
}

.menu:hover::after {
    width: 50%;
}

.setup {
    animation: profile_animate 8s ease-in-out infinite 1s;
}

@keyframes profile_animate {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}
